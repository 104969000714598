import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { Box, Typography, Card, Button, Divider } from '@mui/material';
import TabPanel from '../tabPanel/tabPanel';
import TabsWrapper from '../tabsWrapper/tabsWrapper';
import MonthsTab from '../monthsTab/monthsTab';
import BasicLineChart from '../basicLineChart/basicLineChart';
import { useGlobalState } from '../../context/globalState';
import { useReportState } from '../../context/reportState';
import { TableMetrics, Table } from '../../types/Common.interfaces';
import { format, parseISO, startOfMonth, endOfMonth, isAfter } from 'date-fns';

interface Props {
  currentTab: string;
  tabValue: string;
  handleTabChange: (value: string) => void;
  metricsData: TableMetrics[];
  selectedMetrics: TableMetrics | null;
  monthsValue: number;
  setMonthsValue: (value: number) => void;
  setSelectedMetrics: (value: TableMetrics | null) => void;
  table: string;
}

const HomepageChart: NextPage<Props> = ({
  currentTab, tabValue, handleTabChange, metricsData, selectedMetrics, monthsValue, setMonthsValue, setSelectedMetrics, table
}) => {
  const { totalPersons, totalCompanies, loadingOrganizationData, numberFormatter, tables, maxEffectiveToDate, dateOfFirstRelease } = useGlobalState();
  const {
    setSegmentSelected, setTable, setSelectedTable, setSelectedStatus, setDateRangeValue, setFromDate, setToDate, setRunReport,
    setUpdatedStatusAdded, setCompareValues, setReportSegmentFilters
  } = useReportState();
  const router = useRouter();

  const handleSelectMetrics = (event: any) => {
    const findSelectedMetrics = metricsData.find((value: TableMetrics) => value.date === event.activeLabel);
    setSelectedMetrics(findSelectedMetrics ? findSelectedMetrics : null);
  };

  const renderMetricRange = (): string => {
    if (selectedMetrics) {
      return `${format(parseISO(selectedMetrics.date), 'MMMM yyyy')} total changes`;
    } else if (metricsData.length) {
      const slicedMetricsData = metricsData.slice(-monthsValue);
      const firstMetrics = slicedMetricsData[0];
      const lastMetrics = slicedMetricsData[slicedMetricsData.length - 1];
      return `${format(parseISO(firstMetrics.date), 'MMMM yyyy')} - ${format(parseISO(lastMetrics.date), 'MMMM yyyy')} total changes`;
    } else {
      return '';
    }
  };

  const renderMetricsTotalChanges = (entity: string): string | number => {
    if (selectedMetrics) {
      if (entity === 'total') {
        const total = selectedMetrics.added + selectedMetrics.removed + selectedMetrics.updated;
        return numberFormatter(total);
      } else {
        const total = selectedMetrics[entity];
        return numberFormatter(total);
      }
    } else if (metricsData.length) {
      const slicedMetricsData = metricsData.slice(-monthsValue);
      const total = slicedMetricsData.reduce((prevNumber: number, currentValue: TableMetrics) => {
        if (entity === 'total') {
          prevNumber += currentValue.added + currentValue.removed + currentValue.updated;
        } else {
          prevNumber += currentValue[entity];
        }
        return prevNumber;
      }, 0);
      return numberFormatter(total);
    } else {
      return '';
    }
  };

  const handleReportClick = (statuses: string[]) => {
    setSegmentSelected('complete');
    const findTable = tables.find((item: Table) => item.name.toLowerCase() === table.toLowerCase());
    setTable(findTable ? findTable : null);
    setSelectedTable(findTable ? findTable.id : '');
    setSelectedStatus(statuses);
    setDateRangeValue('Custom');
    const maxDate = parseISO(maxEffectiveToDate);
    const minDate = parseISO(dateOfFirstRelease);
    if (selectedMetrics) {
      const startOfMonthDate = startOfMonth(parseISO(selectedMetrics.date));
      const endOfMonthDate = endOfMonth(parseISO(selectedMetrics.date));
      const isAfterStartDate = isAfter(minDate, startOfMonthDate);
      const isAfterDate = isAfter(endOfMonthDate, maxDate);
      setFromDate(isAfterStartDate ? minDate : startOfMonthDate);
      setToDate(isAfterDate ? maxDate : endOfMonthDate);
    } else if (metricsData.length) {
      const slicedMetricsData = metricsData.slice(-monthsValue);
      const firstMetrics = slicedMetricsData[0];
      const lastMetrics = slicedMetricsData[slicedMetricsData.length - 1];
      const startOfMonthDate = startOfMonth(parseISO(firstMetrics.date));
      const isAfterStartDate = isAfter(minDate, startOfMonthDate);
      const endOfMonthDate = endOfMonth(parseISO(lastMetrics.date));
      const isAfterDate = isAfter(endOfMonthDate, maxDate);
      setFromDate(isAfterStartDate ? minDate : startOfMonthDate);
      setToDate(isAfterDate ? maxDate : endOfMonthDate);
    }
    setRunReport(true);
    router.push('/reports/new');
  };

  return (
    <TabPanel value={currentTab} index={tabValue}>
      <Card className='homepage-chart-card u-p-16 u-mt-32' elevation={2}>
        <Box className='u-flex u-flex-align-start u-flex-justify-start'>
          <Box className='homepage-chart'>
            <Typography className='fs-title-big'>
              Your Data Reports
            </Typography>
            <Box className='u-w-100 u-pt-12'>
              <TabsWrapper
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                loading={loadingOrganizationData}
                companyTotalCount={totalCompanies}
                peopleTotalCount={totalPersons}
                showRelation={false}
                modifyStyles={true}
                metrics={true}
              />
              <Box className='u-w-100'>
                {metricsData.length ? (
                  <BasicLineChart
                    data={metricsData}
                    monthsValue={monthsValue}
                    handleSelectMetrics={handleSelectMetrics}
                    selectedPoint={!!selectedMetrics}
                  />
                ) : (
                  <Box className='u-w-100 u-flex u-flex-align-center u-flex-justify-center' sx={{ height: '200px' }} />
                )}
              </Box>
            </Box>
          </Box>
          <Box className='homepage-tabs u-px-8'>
            <MonthsTab
              monthsValue={monthsValue}
              setMonthsValue={setMonthsValue}
              chartDataLength={metricsData.length}
              setSelectedMetrics={setSelectedMetrics}
            />
            <Box>
              <Box className='u-pt-24'>
                <Typography className='u-text-align-center'>
                  {renderMetricRange()}
                </Typography>
              </Box>
              <Box className='u-py-16 u-px-0'>
                <Box className='u-flex u-flex-align-center u-flex-space-between u-py-12'>
                  <Box className='u-flex u-flex-align-center u-flex-justify-start'>
                    <Box className='u-w-16-px u-h-16-px u-mr-16' sx={{ background: '#25a69a' }}></Box>
                    <Typography className='homepage-metrics-label'>Added</Typography>
                  </Box>
                  <Box className='u-flex u-flex-justify-end u-flex-align-center'>
                    <Box component='span'>
                      {renderMetricsTotalChanges('added')}
                    </Box>
                    <Button
                      className='details-button u-ml-16'
                      onClick={() => {
                        setReportSegmentFilters([]);
                        setCompareValues(false);
                        setUpdatedStatusAdded(false);
                        handleReportClick(['added']);
                      }}
                    >
                      View
                    </Button>
                  </Box>
                </Box>
                <Box className='u-flex u-flex-align-center u-flex-space-between u-py-12'>
                  <Box className='u-flex u-flex-align-center u-flex-justify-start'>
                    <Box className='u-w-16-px u-h-16-px u-mr-16' sx={{ background: '#ff9400' }}></Box>
                    <Typography className='homepage-metrics-label'>Updated</Typography>
                  </Box>
                  <Box className='u-flex u-flex-justify-end u-flex-align-center'>
                    <Box component='span'>
                      {renderMetricsTotalChanges('updated')}
                    </Box>
                    <Button
                      className='details-button u-ml-16'
                      onClick={() => {
                        setReportSegmentFilters([]);
                        setCompareValues(false);
                        setUpdatedStatusAdded(true);
                        handleReportClick(['updated']);
                      }}
                    >
                      View
                    </Button>
                  </Box>
                </Box>
                <Box className='u-flex u-flex-align-center u-flex-space-between u-py-12'>
                  <Box className='u-flex u-flex-align-center u-flex-justify-start'>
                    <Box className='u-w-16-px u-h-16-px u-mr-16' sx={{ background: '#b3261e' }}></Box>
                    <Typography className='homepage-metrics-label'>Flagged for Removal</Typography>
                  </Box>
                  <Box className='u-flex u-flex-justify-end u-flex-align-center'>
                    <Box component='span'>
                      {renderMetricsTotalChanges('removed')}
                    </Box>
                    <Button
                      className='details-button u-ml-16'
                      onClick={() => {
                        setReportSegmentFilters([]);
                        setCompareValues(false);
                        setUpdatedStatusAdded(false);
                        handleReportClick(['removed']);
                      }}
                    >
                      View
                    </Button>
                  </Box>
                </Box>
                <Divider className='divider u-my-12' />
                <Box className='u-flex u-flex-align-center u-flex-space-between u-py-12'>
                  <Box className='u-flex u-flex-align-center u-flex-justify-start'>
                    <Box className='u-w-12-px u-h-2-px u-mr-16' sx={{ background: '#2F3B4A' }}></Box>
                    <Typography className='homepage-metrics-label'>Total Data Changes</Typography>
                  </Box>
                  <Box className='u-flex u-flex-justify-end u-flex-align-center'>
                    <Box component='span'>
                      {renderMetricsTotalChanges('total')}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </TabPanel>
  );
};

export default HomepageChart;