import type { NextPage } from 'next';
import { Box } from '@mui/material';
import { XAxis, YAxis, Tooltip, Line, ComposedChart, CartesianGrid, Bar, ResponsiveContainer } from 'recharts';
import { TableMetrics } from '../../types/Common.interfaces';
import { format, parseISO } from 'date-fns';

interface Props {
  data: TableMetrics[];
  monthsValue: number;
  handleSelectMetrics: (event: any) => void;
  selectedPoint: boolean;
};

const BasicLineChart: NextPage<Props> = ({ data, monthsValue, handleSelectMetrics, selectedPoint }) => {
  const formatXAxis = (tickItem: string) => format(parseISO(tickItem), 'MMMM yyyy');

  const formatYAxis = (tickItem: string) => {
    return tickItem.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <Box className='u-mt-32'>
      <ResponsiveContainer width='99%' height={200}>
        <ComposedChart
          width={1050}
          height={200}
          data={data.slice(-monthsValue)}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          onClick={(event: any) => handleSelectMetrics(event)}
        >
          <CartesianGrid stroke='#f5f5f5' vertical={false} />
          <XAxis
            dataKey='date'
            tickFormatter={formatXAxis}
            tick={{ fontSize: 12 }}
            tickLine={false}
            interval={0}
          />
          <Tooltip
            content={<></>}
            cursor={{ stroke: 'rgba(217,217,217,0.3)', strokeWidth: selectedPoint ? monthsValue > 6 ? 80 : 120 : 0 }}
            trigger='click'
          />
          <YAxis
            dataKey='total'
            tick={{ fontSize: 12 }}
            tickFormatter={formatYAxis}
            yAxisId='left'
            tickLine={false}
            axisLine={false}
          />
          <YAxis
            dataKey='total'
            tick={{ fontSize: 12 }}
            tickFormatter={formatYAxis}
            orientation='right'
            yAxisId='right'
            tickLine={false}
            axisLine={false}
          />
          <Line
            dataKey='total'
            stroke='#2F3B4A'
            strokeWidth={2}
            dot={{ stroke: '#acb1b7', strokeWidth: 5, r: 7, fill: '#2F3B4A' }}
            activeDot={{ stroke: '#acb1b7', strokeWidth: 3, r: 7, fill: '#2F3B4A' }}
            yAxisId='left'
          />
          <Bar dataKey='added' barSize={monthsValue > 6 ? 16 : 20} fill='#25a69a' yAxisId='left' />
          <Bar dataKey='removed' barSize={monthsValue > 6 ? 16 : 20} fill='#b3261e' yAxisId='left' />
          <Bar dataKey='updated' barSize={monthsValue > 6 ? 16 : 20} fill='#ff9400' yAxisId='left' />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default BasicLineChart;
