import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useGlobalState } from '../../context/globalState';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import { Box, Typography, Skeleton, IconButton, Tooltip, Card } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface Props {
  setOpenDrawer: (value: boolean) => void;
  kpiNumber: number;
  loading: boolean;
  headerText: string;
  iconElement: React.ReactElement;
  handleClick?: () => void;
  tooltipText: string;
  kpiNumbers?: {
    phoneNumber: number;
    emailNumber: number;
    loadingKpis: boolean;
  };
}

const HomepageKpi: NextPage<Props> = ({
  setOpenDrawer, kpiNumber, loading, headerText, iconElement, handleClick, tooltipText, kpiNumbers
}) => {
  const { numberFormatter, defaultRowsPerPage } = useGlobalState();
  const { setAudienceFilters } = useBasicFiltersState();
  const router = useRouter();

  return (
    <Card className={`u-flex u-p-16 kpi-item-wrapper u-br-8-px ${kpiNumbers ? 'u-flex-60' : 'u-flex-33'}`} elevation={2}>
      {iconElement}
      <Box
        className='u-flex u-flex-direction-column u-flex-space-between u-pr-12 text-container'
        sx={{ flexGrow: 1 }}
      >
        <Box>
          <Typography className='u-flex u-flex-align-center u-mb-12 fs-title-regular' sx={{ lineHeight: '34px' }}>
            {headerText}
            <Tooltip title={tooltipText} placement='top-start'>
              <IconButton
                size='small'
                className='u-flex u-flex-align-center u-flex-justify-center u-ml-16'
                onClick={() => setOpenDrawer(true)}
              >
                <HelpOutlineIcon className='u-cursor-pointer help-icon' />
              </IconButton>
            </Tooltip>
          </Typography>
          <Box>
            {loading ? (
              <Skeleton variant='rectangular' height={30} sx={{ bgcolor: '#F5EEFA' }} />
            ) : (
              <Box
                component='span'
                className={`u-fw-700 fs-title-big ${handleClick ? 'u-cursor-pointer hero-kpi u-text-decoration-underline' : ''}`}
                onClick={() => handleClick && handleClick()}
              >
                {numberFormatter(kpiNumber)}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {kpiNumbers ? (
        <Box className='u-flex-60 u-text-align-right'>
          <Typography className='fs-body-regular u-pb-12 u-pt-6 u-flex u-flex-justify-end u-flex-align-center u-fw-500'>
            Cell Phones
            {kpiNumbers.loadingKpis ? (
              <Skeleton variant='rectangular' height={24} width={50} className='u-ml-8 u-display-inline-block' sx={{ bgcolor: '#F5EEFA' }} />
            ) : (
              <Box
                component='span'
                className='fs-title-regular u-fw-700 u-ml-8 u-cursor-pointer hero-kpi u-text-decoration-underline'
                onClick={() => {
                  setAudienceFilters([
                    { member: 'TAM.cellphone_per', operator: 'set' } as any
                  ]);
                  router.push(`/database?page=1&page-size=${defaultRowsPerPage}&tab=people`);
                }}
              >
                {kpiNumbers.phoneNumber.toLocaleString()}
              </Box>
            )}
          </Typography>
          <Typography className='fs-body-regular u-flex u-flex-justify-end u-flex-align-center u-fw-500'>
            Verified Emails
            {kpiNumbers.loadingKpis ? (
              <Skeleton variant='rectangular' height={24} width={50} className='u-ml-8 u-display-inline-block' sx={{ bgcolor: '#F5EEFA' }} />
            ) : (
              <Box
                component='span'
                className='fs-title-regular u-fw-700 u-ml-8 u-cursor-pointer hero-kpi u-text-decoration-underline'
                onClick={() => {
                  setAudienceFilters([
                    { member: 'TAM.email_status_per', operator: 'equals', values: ['Verified'] } as any
                  ]);
                  router.push(`/database?page=1&page-size=${defaultRowsPerPage}&tab=people`);
                }}
              >
                {kpiNumbers.emailNumber.toLocaleString()}
              </Box>
            )}
          </Typography>
        </Box>
      ) : null}
    </Card>
  );
};

export default HomepageKpi;
