import type { NextPage } from 'next';
import { Box, Tabs, Tab } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { TableMetrics } from '../../types/Common.interfaces';

interface Props {
  monthsValue: number;
  setMonthsValue: (value: number) => void;
  chartDataLength: number;
  setSelectedMetrics: (value: TableMetrics | null) => void;
}

const MonthsTab: NextPage<Props> = ({ monthsValue, setMonthsValue, chartDataLength, setSelectedMetrics }) => {
  const isMonthActive = (value: number): React.ReactElement | null => {
    return monthsValue === value ? <CheckIcon sx={{ color: '#fff', marginRight: '8px' }} fontSize='small' /> : null;
  };

  return (
    <Box>
      <Tabs
        value={monthsValue}
        onChange={(event: React.SyntheticEvent, value: number) => {
          setSelectedMetrics(null);
          setMonthsValue(value);
        }}
        centered
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <Tab
          label={
            <Box component='span' className='u-flex u-flex-align-center fs-button-text'>
              {isMonthActive(3)}
              3 MONTHS
            </Box>
          }
          value={3}
          classes={{
            root: 'month-tab-left',
            selected: 'month-tab-selected',
            disabled: 'month-tab-disabled'
          }}
        />
        <Tab
          label={
            <Box component='span' className='u-flex u-flex-align-center fs-button-text'>
              {isMonthActive(6)}
              6 MONTHS
            </Box>
          }
          value={6}
          classes={{
            root: 'month-tab-center',
            selected: 'month-tab-selected',
            disabled: 'month-tab-disabled'
          }}
          disabled={chartDataLength <= 3}
        />
        <Tab
          label={
            <Box component='span' className='u-flex u-flex-align-center fs-button-text'>
              {isMonthActive(12)}
              12 MONTHS
            </Box>
          }
          value={12}
          classes={{
            root: 'month-tab-right',
            selected: 'month-tab-selected',
            disabled: 'month-tab-disabled'
          }}
          disabled={chartDataLength <= 6}
        />
      </Tabs>
    </Box>
  );
};

export default MonthsTab;
