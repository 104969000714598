import type { NextPage } from 'next';
import { Box, Card, Skeleton } from '@mui/material';

const LoadingChart: NextPage = () => {
  return (
    <Card className='homepage-chart-card u-p-16 u-mt-32' elevation={2}>
      <Box className='u-flex u-flex-align-start u-flex-justify-start'>
        <Box className='u-flex-70'>
          <Skeleton variant='rectangular' width='60%' height={35} sx={{ bgcolor: '#F5EEFA' }} />
          <Box className='u-flex u-flex-align-start u-flex-justify-start'>
            <Box className='u-mr-32'>
              <Skeleton variant='rectangular' className='u-mt-32' height={48} width={140} sx={{ bgcolor: '#F5EEFA' }} />
              <Skeleton variant='rectangular' className='u-mt-4' height={24} width={140} sx={{ bgcolor: '#F5EEFA' }} />
            </Box>
            <Box>
              <Skeleton variant='rectangular' className='u-mt-32' height={48} width={140} sx={{ bgcolor: '#F5EEFA' }} />
              <Skeleton variant='rectangular' className='u-mt-4' height={24} width={140} sx={{ bgcolor: '#F5EEFA' }} />
            </Box>
          </Box>
          <Skeleton  variant='rectangular' className='u-mt-4' height={276} width='100%' sx={{ bgcolor: '#F5EEFA' }} />
        </Box>
        <Box className='u-flex-30 u-pl-48'>
          <Skeleton variant='rectangular' width='100%' height={45} sx={{ bgcolor: '#F5EEFA' }} />
          <Skeleton variant='rectangular' className='u-mt-32' width='100%' height={45} sx={{ bgcolor: '#F5EEFA' }} />
          <Skeleton variant='rectangular' className='u-mt-16' width='100%' height={45} sx={{ bgcolor: '#F5EEFA' }} />
          <Skeleton variant='rectangular' className='u-mt-16' width='100%' height={45} sx={{ bgcolor: '#F5EEFA' }} />
          <Skeleton variant='rectangular' className='u-mt-16' width='100%' height={45} sx={{ bgcolor: '#F5EEFA' }} />
          <Skeleton variant='rectangular' className='u-mt-32' width='100%' height={45} sx={{ bgcolor: '#F5EEFA' }} />
        </Box>
      </Box>
    </Card>
  );
};

export default LoadingChart;
